import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-total',
    templateUrl: './card-total.component.html',
    styleUrls: ['./card-total.component.scss']
})
export class CardTotalComponent {
    @Input() public price;
    @Input() public url;

    public goToUrl(): void {
        window.open(this.url, '_blank');
    }

    public scrollToTop(): void {
        window.scrollTo(0, 0);
    }

}
