// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export class URL {
    // DEVELOP.
    public static readonly OPENFACTURA_WEBSITE = 'https://website-of.haulmer.xyz';
    public static readonly OPENFACTURA_APP = 'https://espacio.haulmer.com';
    public static readonly OPENFACTURA_SIGNUP = 'https://www.openfactura.cl/cart';
    public static readonly OPENFACTURA_SIGNUP_DEMO = 'https://learn-openfactura.haulmer.com'; // demo solo disponible en producción.
    // tslint:disable-next-line:max-line-length
    public static readonly OPENFACTURA_SIGNUP_DEMO_CUSTOMPDF = 'https://dev-openfactura.dev.dcos.haulmer.net/dte/configuration/personalization'; // demo solo disponible en produccion
    public static readonly OPENFACTURA_TERMS_CONDITIONS = 'https://www.haulmer.com/legal/terminos-y-condiciones-openfactura/';
    public static readonly OPENFACTURA_PRIVACY_POLICY = 'https://www.haulmer.com/legal/politica-de-privacidad/';
    public static readonly OPENFACTURA_DOCS = 'https://docs.openfactura.cl';
    public static readonly OPENFACTURA_DOCS_API = 'https://docsapi-openfactura.haulmer.com/';
    public static readonly OPENFACTURA_FACEBOOK = 'https://www.facebook.com/openfactura';
    public static readonly OPENFACTURA_TWITTER = 'https://twitter.com/openfacturalat';
    public static readonly OPENFACTURA_YOUTUBE = 'https://www.youtube.com/channel/UC6NwudUw1i0Z_cQwMy8h3iA';
    public static readonly OPENFACTURA_LINKEDIN = 'https://www.linkedin.com/company/haulmer';
    public static readonly HAULMER_WEBSITE = 'https://www.haulmer.com';
    public static readonly HAULMER_STATUS = 'https://status.haulmer.xyz';
    public static readonly HAULMER_HELP = 'https://help.haulmer.xyz';
    public static readonly HAULMER_ACCOUNT = 'https://accounts.haulmer.xyz';
    public static readonly HAULMER_WORK_FOR_US = 'mailto:career@haulmer.com';
    public static readonly HAULMER_EVENTS = 'https://events.haulmer.com';
    public static readonly BLUEHOSTING_WEBSITE = 'https://www.bluehosting.cl';
    public static readonly OPENCLOUD_WEBSITE = 'https://www.opencloud.cl';
    public static readonly PAGOMASTER_WEBSITE = 'https://www.pagomaster.cl';
    public static readonly OPENCARGO_WEBSITE = 'https://www.opencargo.cl';
    public static readonly BOARD_SERVICE = 'https://api-frontend.haulmer.xyz/v1/call';
    public static readonly OPENFACTURA_CONTENT = 'https://www.openfactura.cl/articulos/';
    public static readonly HAULMER_BLOG = 'https://www.haulmer.com/blog/';
    public static readonly HAULMER_NEWS = 'https://www.haulmer.com/news/';
    public static readonly CHILEFIRMAS_WEBSITE = 'https://www.chilefirmas.cl/';

}
