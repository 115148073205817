import { ElementRef } from '@angular/core';

export abstract class ScrollToClass {

    constructor(public parentEl: ElementRef) {
    }

    scrollToSection(className, headerHeight?: number) {
        const componentEl = this.parentEl.nativeElement;

        const targetSection = document.getElementsByClassName(className)[0] as HTMLElement;

        // leave if not found.
        if (targetSection === undefined || targetSection === null) {
            return;
        }

        const targetBounds = targetSection.getBoundingClientRect();
        const isTargetInViewport = targetBounds.top > 64 && targetBounds.bottom < window.innerHeight;

        if (isTargetInViewport && targetBounds.top <= window.innerHeight / 4) {
            //  do nothing
            // IF target section is completely visible
            // AND it's top is on the first square of the screen's height (good visible)
        } else {
            let sectionPosition = targetSection.offsetTop;
            // Scroll with "offset" as data attribute.
            if (!!targetSection.dataset.offset) {
                sectionPosition = targetSection.offsetTop - +targetSection.dataset.offset;
            }
            // scroll to target component
            this._scrollTo(
                componentEl,
                sectionPosition,
                300,
                headerHeight);
        }
    }

    private _scrollTo(element, to, duration, hHeight?: number) {
        const start = window.pageYOffset || element.scrollTop || document.body.scrollTop || 0;
        const headerHeight: number = !!hHeight ? hHeight : 64;
        const change = to - start - headerHeight;
        let currentTime = 0;
        const increment = 20;

        const easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return c / 2 * t * t + b;
            }
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        const animateScroll = function () {
            currentTime += increment;
            window.scrollTo(0, easeInOutQuad(currentTime, start, change, duration));

            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }
}
