import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-integrations-contact',
    templateUrl: './integrations-contact.component.html',
    styleUrls: ['./integrations-contact.component.sass']
})
export class IntegrationsContactComponent implements OnInit {
    @Input() loadAnimation = false;

    constructor() {
    }

    ngOnInit() {
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
