import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-integrations-integration',
    templateUrl: './integrations-integration.component.html',
    styleUrls: ['./integrations-integration.component.sass']
})
export class IntegrationsIntegrationComponent implements OnInit {
    @ViewChild('animateSteps', { static: true }) animateSteps: ElementRef;

    public listSteps = [
        {
            id: 1,
            icon: 'installation',
            bg_color: 'bg-light-purple',
            ttl: 'Contrata',
            text: 'Elige el plan de OpenFactura que mejor se adapte a tu negocio.',
            size: 140
        }, {
            id: 2,
            icon: 'installation-2',
            bg_color: 'bg-light-rose',
            ttl: 'Descarga',
            text: 'Visita el marketplace que corresponda e instala nuestro plugin, addon o app.',
            size: 124
        }, {
            id: 3,
            icon: 'installation-3',
            bg_color: 'bg-light-blue',
            ttl: 'Listo!',
            text: 'Escoge el modo manual o automático para emitir tus documentos.',
            size: 124
        }
    ];

    @HostListener('window:scroll')
    onScroll(): void {
        this.addClassAnimation();
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    ngOnInit() {
    }

    private addClassAnimation() {
        const steps = this.animateSteps.nativeElement;

        if (this.isInViewport(steps)) {
            steps.classList.add('animate-steps');
        } else {
            steps.classList.remove('animate-steps');
        }
    }

    private isInViewport(elem) {
        if (isPlatformBrowser(this.platformId)) {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.bottom - 150 <= (window.innerHeight || this.document.documentElement.clientHeight)
            );
        }
    }

}
