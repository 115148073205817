import { Component, OnInit } from '@angular/core';
import { URL } from '../../../environments/environment';

@Component({
    selector: 'app-block-logo',
    templateUrl: './block-logo.component.html'
})
export class BlockLogoComponent implements OnInit {
    public URL = URL;

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
}
