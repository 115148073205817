import { IDataStrip } from './../../interfaces/promotions.interface';
import { AppType } from './../../assets/website-data';
import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-strip',
    templateUrl: './strip.component.html',
    styleUrls: ['./strip.component.scss']
})

export class StripComponent {
    @Input() data: IDataStrip;
    @Input() appType: AppType;
    public svgSize = { width: '70px', height: '35px' };
}