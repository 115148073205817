import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { traceButton } from '../../services/button-trace';
import { IDataModalTemplate, IModalData } from '../../interfaces/promotions.interface';
import { AppType } from '../../../common/assets/website-data';

@Component({
  selector: 'app-dialog-promo',
  templateUrl: './dialog-promo.component.html',
  styleUrls: ['./dialog-promo.component.scss']
})
export class DialogPromoComponent implements OnInit {
  @Input() dataModal: IDataModalTemplate;
  @Input() appType: AppType;
  public logoWidth = '45px';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IModalData,
    private dialog: MatDialogRef<DialogPromoComponent>
    ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.logoWidth = window.innerWidth < 768 ? '40px' : '45px';
  }

  ngOnInit(): void {
    this.logoWidth = window.innerWidth < 768 ? '40px' : '45px';
  }

  public useButton(event: any): void {
    traceButton(event, 'scroll');
    this.dialog.close({ status: true, prCode: this.dataModal.promoCode.code });
  }
}
