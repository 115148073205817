import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { URL } from '../../environments/environment';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html'
})
export class FeaturesComponent implements OnInit {
    public URL = URL;
    isSecond: boolean = false;
    isSecondTab: boolean = false;

    // lazy loading images
    showImage1: boolean = false;
    showImage2: boolean = false;
    showImage3: boolean = false;
    showImage4: boolean = false;
    showImage5: boolean = false;
    showImage6: boolean = false;
    showImage7: boolean = false;
    showImage8: boolean = false;

    constructor(private _el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        private readonly _meta: MetaService,
    ) {}

    ngOnInit() {
        this.addClassTabAndroid();

        this._meta.setTitle('FACTURACIÓN ELECTRÓNICA | Factura Electrónica 100% Cloud de Chile');
        this._meta.setTag('description', 'OpenFactura es la Factura Electrónica 100% Cloud de Chile con la Más Alta Calidad y Mejor Precio del Mercado.');
        this._meta.setTag('keywords', 'factura electrónica, sistema de factura electrónica, plataforma de factura electrónica, factura electrónica ilimitada, facturación electrónica, sistema de facturación electrónica, plataforma facturación electrónica, facturación electrónica chile, facturación electrónica ilimitada, boleta electrónica, boleta electrónica ilimitada, guía de despacho electrónica, dte, dte ilimitados');
        this._meta.setTag('author', 'OpenFactura');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENFACTURA_WEBSITE + '/assets/images/sistema_facturacion_electronica.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Sistema Factura Electrónica Ilimitada #1 en Chile');
        this._meta.setTag('og:localte', 'es_CL');
    }

    toggleVideo(id) {
        this.isSecond = id !== 'tabVideoOne';
        //this.playVideoElement(id);
    }

    hoverVideo(id) {
        this.isSecondTab = id !== 'tab2VideoOne';
        //this.playVideoElement(id);
    }

    playVideoElement(id) {
        if (this._document.getElementsByTagName('body')[0].classList.contains('video')) {
            const video = this._document.getElementById(id) as HTMLVideoElement;
            video.load();
            if (this.isInViewport(video)) {
                video.play();
            } else {
                video.pause();
            }
        }

    }

    addClassTabAndroid() {
        const itemTab = this._document.getElementsByClassName('block_video_tab')[0] as HTMLElement;
        const itemTab2 = this._document.getElementsByClassName('block_video_tab2')[0] as HTMLElement;
    }

    isInViewport(elem) {
        if (isPlatformBrowser(this.platformId)) {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 104 && bounding.bottom <= (window.innerHeight || this._document.documentElement.clientHeight)
            );
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    goToUrl(url: string): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

}
