import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-section-slider-tab',
    templateUrl: './section-slider-tab.component.html',
    styleUrls: ['./section-slider-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SectionSliderTabComponent {
    @Input() public loadedImage: boolean;

    public activeTabIndex = 0;
    public oldTabIndex = this.activeTabIndex;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
    ) { }

    tabChanged(): void {
        const videoContainer = this._document.getElementsByClassName('sectionSliderTab__preview')[0] as HTMLElement;
        const videoItems = videoContainer.getElementsByClassName('sectionSliderTab__preview-img');
        const targetItem = videoItems[this.activeTabIndex];

        [].forEach.call(videoItems, (item) => {
            item.classList.remove('active', 'toRight', 'toLeft');
        });
        targetItem.classList.add('active');
        this.oldTabIndex = this.activeTabIndex;
    }

}
