import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'app-section-training',
    templateUrl: './section-training.component.html',
    styleUrls: ['./section-training.component.scss']
})
export class SectionTrainingComponent {
    @Input() public loadedImage;

    public list = [
        '✔︎ Agenda tu hora con un asesor',
        '✔︎ Recibir una capacitación inicial',
        '✔︎ Resolvemos tus principales dudas',
        '✔︎ Te guiamos en todo el proceso para utilizar el servicio'
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    public scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }
}
