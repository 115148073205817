import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { URL } from '../../environments/environment';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html'
})
export class PriceComponent implements OnInit {
    public URL = URL;

    // lazy loading images
    showImage1: boolean = false;

    // data tabPlan
    dataPlans;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService
    ) {
    }

    ngOnInit() {
        this._meta.setTitle('Precios | OpenFactura Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenFactura es el software de facturación electrónica #1 en Chile. Desde $19.900 anual podrás crear todos tus documentos tributarios; sin límites de emisión.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'facturación electrónica, factura electronica, sistema de facturación electrónica, plataforma facturación electrónica, facturación electrónica chile, facturación electrónica ilimitada, dte');
        this._meta.setTag('author', 'OpenFactura');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENFACTURA_WEBSITE + '/assets/images/sistema_facturacion_electronica.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Sistema Facturación Electrónica Ilimitada #1 en Chile');
        this._meta.setTag('og:localte', 'es_CL');
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
