import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-code-highlighting',
    templateUrl: './code-highlighting.component.html'
})
export class CodeHighlightingComponent implements OnInit {
    selectedButton: string = 'cUrl';
    codeList = ['cUrl', 'jQuery', 'Ruby', 'Phyton', 'Node', 'PHP', 'Go'];
    isMobile: boolean;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = event.target.innerWidth >= 670 ? false : true;
    }

    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth >= 670 ? false : true;
        }
    }

    selectedBtn(btn) {
        this.selectedButton = btn;

    }
}
