import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-doc-issueance',
    templateUrl: './section-doc-issueance.component.html',
    styleUrls: ['./section-doc-issueance.component.scss']
})
export class SectionDocIssueanceComponent {
    @Input() public loadedImage: boolean;

    goToUrl(url): void {
        window.open(url, '_blank');
    }

}
