import { Component, OnInit, Output } from '@angular/core';
import { ProjectsUrl } from '../../../../common/projects-url';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';
import { CONTACTS, IContact } from '../../../../common/contacts.constants';

@Component({
    selector: 'app-contact-support-wrapper',
    templateUrl: './contact-support-wrapper.component.html'
})
export class ContactSupportWrapperComponent extends ProjectsUrl implements OnInit {
    @Output() Meta: Meta;

    public URL = URL;
    listUrl;
    contactList: Array<IContact>;

    constructor() {
        super();
        this.contactList = CONTACTS.filter(item => item.country === 'Chile');
    }

    ngOnInit() {
        this.Meta = new Meta(
            'Contactar con Soporte | Openfactura',
            'Envíanos tu ticket al área de soporte. Para resolver tus dudas e inconvenientes acude al centro de soporte técnico de Openfactura; disponible 24/7 todo el año',
            'Openfactura',
            this.URL.OPENFACTURA_WEBSITE + '/assets/images/og_haulmer_support.png',
            'png',
            644,
            374,
            'Contactar con Soporte, Openfactura'
        );

        this.Meta.addKeyword('contactar con soporte');
        this.Meta.addKeyword('servicio técnico');
        this.Meta.addKeyword('soporte');
        this.Meta.addKeyword('servicios de soporte');
        this.Meta.addKeyword('soporte técnico');

        this.listUrl = this.getProjectList('openfactura');
    }

}
