import { CONTACTS } from './../../../../common/contacts.constants';
import { Component, OnInit, Inject, Output } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-contact-wrapper',
    templateUrl: './contact-wrapper.component.html',

})
export class ContactWrapperComponent implements OnInit {
    public URL = URL;
    playVideo = false;

    @Output() TLD = 'cl';
    @Output() Meta: Meta;
    @Output() MessageFrom = 'OpenFactura';
    @Output() ContactList = CONTACTS.filter(c => c.country_code === 'cl');

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private route: ActivatedRoute
    ) {
        this.Meta = new Meta(
            'Contactar con ventas | OpenFactura',
            'Completa tus datos y en pocos segundos uno de nuestros ejecutivos de venta se comunicará contigo. Descubre todo lo que Openfactura tiene para ofrecer.',
            'Bluehosting',
            this.URL.OPENFACTURA_WEBSITE + '/assets/images/og_haulmer.png',
            'png',
            644,
            374,
            'Contacto con ventas, OpenFactura'
        );

        this.Meta.addKeyword('contactar ventas');
        this.Meta.addKeyword('ventas');
        this.Meta.addKeyword('atención al cliente');
        this.Meta.addKeyword('centro de soporte');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.grandes === 'true') {
                this.MessageFrom = 'Openfactura - Plan GRANDES';
            }
            else if (params.partner === 'true'){
                this.MessageFrom = 'Openfactura - Partner';
            }
        });
    }
}
