import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { PriceComponent } from './price/price.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ContactAndRequestQuoteComponent } from './contact-and-request-quote/contact-and-request-quote.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { DevelopersComponent } from './developers/developers.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';

import { IntegrationsComponent } from './integrations/integrations.component';
import { ShopifyComponent } from './integrations-shopify/shopify.component';
import { WhmcsComponent } from './integrations-whmcs/whmcs.component';
import { WoocommerceComponent } from './integrations-woocommerce/woocommerce.component';
import { PrestashopComponent } from './integrations-prestashop/prestashop.component';
import { IntegrationsJumpsellerComponent } from './integrations-jumpseller/integrations-jumpseller.component';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    }, {
        path: 'factura-electronica/caracteristicas/.',
        component: FeaturesComponent
    }, {
        path: 'factura-electronica/caracteristicas',
        redirectTo: 'factura-electronica/caracteristicas/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-con-ventas/.',
        component: ContactWrapperComponent
    }, {
        path: 'contactar-con-ventas',
        redirectTo: 'contactar-con-ventas/.',
        pathMatch: 'full'
    }, {
        path: 'solitar-cotizacion/.',
        component: ContactAndRequestQuoteComponent
    }, {
        path: 'solitar-cotizacion',
        redirectTo: 'solitar-cotizacion/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-con-soporte/.',
        component: ContactSupportWrapperComponent
    }, {
        path: 'contactar-con-soporte',
        redirectTo: 'contactar-con-soporte/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/precios/.',
        component: PriceComponent
    }, {
        path: 'factura-electronica/precios',
        redirectTo: 'factura-electronica/precios/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/api/.',
        component: DevelopersComponent
    }, {
        path: 'factura-electronica/api',
        redirectTo: 'factura-electronica/api/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/integraciones/.',
        component: IntegrationsComponent
    }, {
        path: 'factura-electronica/integraciones',
        redirectTo: 'factura-electronica/integraciones/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/integraciones/shopify/.',
        component: ShopifyComponent
    }, {
        path: 'factura-electronica/integraciones/shopify',
        redirectTo: 'factura-electronica/integraciones/shopify/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/integraciones/whmcs/.',
        component: WhmcsComponent
    }, {
        path: 'factura-electronica/integraciones/whmcs',
        redirectTo: 'factura-electronica/integraciones/whmcs/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/integraciones/woocommerce/.',
        component: WoocommerceComponent
    }, {
        path: 'factura-electronica/integraciones/woocommerce',
        redirectTo: 'factura-electronica/integraciones/woocommerce/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/integraciones/prestashop/.',
        component: PrestashopComponent
    }, {
        path: 'factura-electronica/integraciones/prestashop',
        redirectTo: 'factura-electronica/integraciones/prestashop/.',
        pathMatch: 'full'
    }, {
        path: 'factura-electronica/integraciones/jumpseller/.',
        component: IntegrationsJumpsellerComponent
    }, {
        path: 'factura-electronica/integraciones/jumpseller',
        redirectTo: 'factura-electronica/integraciones/jumpseller/.',
        pathMatch: 'full'
    }, {
        path: 'formas-de-pago/.',
        component: PaymentFormsWrapperComponent
    }, {
        path: 'formas-de-pago',
        redirectTo: 'formas-de-pago/.',
        pathMatch: 'full'
    },

];
