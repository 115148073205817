import { Component, OnInit, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CONTACTS } from '../../../../../common/contacts.constants';

import { URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ScrollToClass } from '../../../../../common/scrollTo.class';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent extends ScrollToClass implements OnInit {
    public URL = URL;
    contactList = CONTACTS.filter(item => item.country === 'Chile');
    currentCountryCode = this.contactList[0].country_code;
    countrySelected: string;
    flagSelected: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private el: ElementRef
    ) {
        super(el);
    }

    ngOnInit() {
        this.countrySelected = this.getCurrentCountry().country;
        this.flagSelected = this.getCurrentCountry().flag_url;
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country_code === this.currentCountryCode);
        return country[0];
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public getPageMenu(): 'integrations-home'|'default' {
        return this.router.url === '/factura-electronica/integraciones/.' ? 'integrations-home' : 'default';
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    selectedCountry(country) {
        const selectedItem = this.contactList.filter(item => item.country === country)[0];
        this.flagSelected = selectedItem.flag_url;
        this.countrySelected = selectedItem.country;
    }
}
