import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CONTACTS, IContact } from './../contacts.constants';
import { Meta } from '../Meta';
import { MetaService } from '@ngx-meta/core';
import { AppType } from '../assets/website-data';

declare var MessageBirdChatWidget: any;

@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html'
})
export class ContactSupportComponent implements OnInit {
    @ViewChild('ticket', { static: true }) public ticket: ElementRef;
    @ViewChild('contacts', { static: true }) public contacts: ElementRef;
    @Input() Meta: Meta;
    @Input() urls;
    @Input() titlePage = 'Contactar con Soporte';
    @Input() showTicket: boolean = true;
    @Input() showWhatsapp: boolean = false;
    @Input() contactList: Array<IContact>;
    @Input() appType: AppType;

    linkSales;
    linkSupport;
    linkPayment;

    constructor(
        private _meta: MetaService,
    ) {
        this.contactList = CONTACTS;
    }

    ngOnInit() {
        this._meta.setTitle(this.Meta.title);
        this._meta.setTag('description', this.Meta.description);

        let keywords = '';

        this.Meta.keywords.forEach(keyword => {
            if (keywords[keywords.length - 1] === keyword) {
                keywords = keywords + keyword;
            } else {
                keywords = keywords + keyword + ', ';
            }
        });

        this._meta.setTag('keywords', keywords);
        this._meta.setTag('author', this.Meta.author);
        this._meta.setTag('og:image:type', 'image/' + this.Meta.image_type);
        this._meta.setTag('og:image', this.Meta.image_path);
        this._meta.setTag('og:image:width', this.Meta.image_width);
        this._meta.setTag('og:image:height', this.Meta.image_height);
        this._meta.setTag('og:image:alt', 'Contactar Soporte');
        this._meta.setTag('og:locale', this.Meta.locale);

        this.linkSales = this.urls.link_sales;
        this.linkSupport = this.urls.link_support;
        this.linkPayment = this.urls.link_payment;
    }

    scrollTo(target: string) {
        if (target === 'ticket') {
            this.ticket.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        } else if (target === 'contacts') {
            this.contacts.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public openMessageBird():void {
        MessageBirdChatWidget.toggleChat(true);
    }
}
