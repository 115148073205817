export interface HeaderDataObject {
    contact_sales_button_id: string;
    contact_sales_button_title: string;
    demonstration_button_id: string;
    demonstration_button_title: string;
    button_support_id: string;
    button_support_title: string;
    button_init_session_id: string;
    button_init_session_title: string;

    submenu_button_1_id: string;
    submenu_button_1_title: string;
    submenu_button_2_id?: string;
    submenu_button_2_title?: string;
    submenu_button_3_id?: string;
    submenu_button_3_title?: string;
    submenu_button_4_id?: string;
    submenu_button_4_title?: string;
}

interface HeaderObjectList {
    default: HeaderDataObject;
    integrations: HeaderDataObject;
}

export const HEADER_DATA: HeaderObjectList = {
    default: {
        contact_sales_button_id: 'of-contactsales',
        contact_sales_button_title: 'Contactar con ventas',
        demonstration_button_id: 'of-header-demo',
        demonstration_button_title: 'Demostración',
        button_support_id: 'of-header-soporte',
        button_support_title: 'Soporte',
        button_init_session_id: 'of-header-iniciarsesion',
        button_init_session_title: 'Iniciar sesión',
        submenu_button_1_id: 'of-header-caracteristicas',
        submenu_button_1_title: 'Características',
        submenu_button_2_id: 'of-header-precios',
        submenu_button_2_title: 'Precios',
        submenu_button_3_id: 'of-header-caracteristicas',
        submenu_button_3_title: 'API',
        submenu_button_4_id: 'of-header-caracteristicas',
        submenu_button_4_title: 'Integraciones'
    },
    integrations: {
        contact_sales_button_id: 'of-i-contactsales',
        contact_sales_button_title: 'Contactar con ventas',
        demonstration_button_id: 'of-i-demostration',
        demonstration_button_title: 'Demostración',
        button_support_id: 'of-header-soporte',
        button_support_title: 'Soporte',
        button_init_session_id: 'of-i-sesión',
        button_init_session_title: 'Iniciar sesión',
        submenu_button_1_id: 'of-i-caracteristicas',
        submenu_button_1_title: 'Características',
        submenu_button_2_id: 'of-i-precios',
        submenu_button_2_title: 'Precios',
        submenu_button_3_id: 'of-i-caracteristicas',
        submenu_button_3_title: 'API',
        submenu_button_4_id: 'of-i-caracteristicas',
        submenu_button_4_title: 'Integraciones'
    }
};
