import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Meta } from '../../../../common/Meta';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-developers',
    templateUrl: './developers.component.html'
})
export class DevelopersComponent implements OnInit {
    @ViewChild('sectionSlack', { static: true }) sectionSlack: ElementRef;
    public URL = URL;
    Meta: Meta;

    isShowGif: boolean = false;

    // lazy loading images
    showImage1: boolean = false;
    showImage2: boolean = false;

    @HostListener('window:scroll')
    onScroll(): void {
        this.playAnimation();
    }

    constructor(
        private _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    ngOnInit() {
        this._meta.setTitle('API Factura Electrónica para Developers e Integradores');
        this._meta.setTag('description', 'API Factura Electrónica te permitirá integrar Openfactura a tus sistemas y aplicaciones; diseñado para developers e integradores, emite tus documentos sin esfuerzo.');
        this._meta.setTag('keywords', 'API factura electrónica, API facturación electrónica, integración factura electrónica, software facturación electrónica, factura electrónica, API rest, API key, API para desarrolladores');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENFACTURA_WEBSITE + '/assets/images/developers/og_developer.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '374');
        this._meta.setTag('og:image:alt', 'API Factura Electrónica');
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    isInViewport(elem) {
        if (isPlatformBrowser(this.platformId)) {
            const bounding = elem.getBoundingClientRect();
            return (
                (bounding.top + 250) > 0 && bounding.bottom <= window.innerHeight + 250
            );
        }
    }

    playAnimation() {
        const blockSlack = this.sectionSlack.nativeElement;

        if (this.isInViewport(blockSlack)) {
            this.isShowGif = true;
        } else {
            this.isShowGif = false;
        }
    }
}
