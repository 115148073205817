import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-integrations-plugins',
    templateUrl: './integrations-plugins.component.html',
    styleUrls: ['./integrations-plugins.component.sass']
})
export class IntegrationsPluginsComponent implements OnInit {
    @Input() public buttonId: string;

    constructor(
        private router: Router
    ) {
    }

    ngOnInit() {
    }

    public goTo(url: string): void {
        window.scroll(0, 0);
        this.router.navigateByUrl(`/factura-electronica/integraciones/${url}/.`);
    }

}
