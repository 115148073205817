import { Component, OnInit, HostBinding, ElementRef, Input } from '@angular/core';
import { CONTACTS, PAYMENTS } from './../contacts.constants';
import { ScrollToClass } from '../scrollTo.class';
import { MetaService } from '@ngx-meta/core';
import { Meta } from '../Meta';
import { Contact } from '../Contact';

@Component({
    selector: 'app-payment-forms',
    templateUrl: './payment-forms.component.html'
})
export class PaymentFormsComponent extends ScrollToClass implements OnInit {
    @HostBinding('class') classList = 'payment-forms';
    @Input() Meta: Meta;
    @Input() TLD: string;
    @Input() headerHeight: number;
    @Input() isHaulmer: boolean = false;
    // lazy laoding images
    public showImage1: boolean = true;

    contact: Contact;
    contactList;
    payments;
    activeCountry: string = 'Chile';

    constructor(
        private _meta: MetaService,
        private _el: ElementRef
    ) {
        super(_el);
        this.contactList = CONTACTS;
        this.payments = PAYMENTS;
    }

    ngOnInit() {
        this._meta.setTitle(this.Meta.title);
        this._meta.setTag('description', this.Meta.description);

        let keywords = '';

        this.Meta.keywords.forEach(keyword => {
            if (keywords[keywords.length - 1] === keyword) {
                keywords = keywords + keyword;
            } else {
                keywords = keywords + keyword + ', ';
            }
        });

        this._meta.setTag('keywords', keywords);
        this._meta.setTag('author', this.Meta.author);
        this._meta.setTag('og:image:type', 'image/' + this.Meta.image_type);
        this._meta.setTag('og:image', this.Meta.image_path);
        this._meta.setTag('og:image:width', this.Meta.image_width);
        this._meta.setTag('og:image:height', this.Meta.image_height);
        this._meta.setTag('og:image:alt', 'Formas de Pago');
        this._meta.setTag('og:locale', this.Meta.locale);

        this.contact = this.getContact(this.TLD);
        this.activeCountry = this.contact.country;
        const currencyCode = this.contactList.filter(item => item.country === this.contact.country)[0].country_code;
        this.navigateToSection(currencyCode + '-ts');
    }

    getDataPayments(country) {
        var currencyPayment;
        if (this.isHaulmer) {
            currencyPayment = this.payments
                .filter(item => item.country === country)[0].payments_method
                .filter(element => {
                    if (!!element.logos) {
                        if (element.logos[0] !== 'logo-linkify') {
                            return element;
                        }
                    }
                });
            return currencyPayment;
        } else {
            currencyPayment = this.payments
                .filter(item => item.country === country)[0].payments_method
                .filter(element => {
                    if (!!element.logos) {
                        if (element.logos[0] !== 'logo-itau') {
                            return element;
                        }
                    }
                });
            return currencyPayment;
        }
    }

    selctedCountry(selctedCountry) {
        this.activeCountry = selctedCountry;
    }

    navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className, this.headerHeight);
        }, 200);
    }

    getContact(TLD: string): Contact {
        const aux = (TLD === 'mx' ? 'me' : TLD);
        return this.contactList.filter(c => c.country_code === aux)[0];
    }
}
