import { Component, OnInit, Output, Inject} from '@angular/core';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-payment-forms-wrapper',
    templateUrl: './payment-forms-wrapper.component.html'
})
export class PaymentFormsWrapperComponent implements OnInit {
    @Output() Meta: Meta;
    @Output() TLD = 'cl';

    public URL = URL;

    constructor(
        @Inject(DOCUMENT) private _document: Document
    ) {}

    ngOnInit() {
        const favicon = this._document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');

        this.Meta = new Meta(
            'Formas de Pago | OpenFactura',
            'Conoce las formas de pago que OpenFactura deja a tu disposición. Cancela tus planes y disfrútalos ¡Ahora!',
            'OpenFactura',
            this.URL.HAULMER_WEBSITE + 'assets/og/images/og-formas-de-pago.png',
            'png',
            644,
            374,
            'Formas de Pago | OpenFactura'
        );

        this.Meta.addKeyword('formas de pagos');
        this.Meta.addKeyword('métodos de pago');
    }
}
