import { Component } from '@angular/core';

@Component({
    selector: 'app-section-preview-machine',
    templateUrl: './section-preview-machine.component.html',
    styleUrls: ['./section-preview-machine.component.scss']
})
export class SectionPreviewMachineComponent {

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
