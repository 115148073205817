export interface IContact {
    city: string;
    country: string;
    country_code: string;
    currency_code: string;
    flag_url: string;
    phone_code: string;
    main_phone: string;
    main_phone_href: string;
    taxez: string;
    currency_number: number;
}

export const CONTACTS = [
    {
        city: 'Santiago',
        country: 'Chile',
        country_code: 'cl',
        currency_code: 'CLP',
        flag_url: '/assets/images/flags/cl.svg',
        phone_code: '+56',
        main_phone: '+56 2 3210 9063',
        main_phone_href: '+56232109063',
        taxez: ' + IVA',
        currency_number: 1
    },
    {
        city: 'Lima',
        country: 'Perú',
        country_code: 'pe',
        currency_code: 'PEN',
        flag_url: '/assets/images/flags/pe.svg',
        phone_code: '+51',
        main_phone: '+51 1 707 0316',
        main_phone_href: '+5117070316',
        taxez: ' + IGV',
        currency_number: 5
    },
    {
        city: 'Ciudad de México',
        country: 'México',
        country_code: 'me',
        currency_code: 'MXN',
        flag_url: '/assets/images/flags/mx.svg',
        phone_code: '+52',
        main_phone: '+52 55 4163 8355',
        main_phone_href: '+525541638355',
        taxez: '',
        currency_number: 7
    },
    {
        city: 'Buenos Aires',
        country: 'Argentina',
        country_code: 'ar',
        currency_code: 'ARS',
        flag_url: '/assets/images/flags/ar.svg',
        phone_code: '+54',
        main_phone: '+54 11 5168 6187',
        main_phone_href: '+541151686187',
        taxez: '',
        currency_number: 10
    },
    {
        city: 'Bogotá',
        country: 'Colombia',
        country_code: 'co',
        currency_code: 'COP',
        flag_url: '/assets/images/flags/co.svg',
        phone_code: '+57',
        main_phone: '+57 601 328 9068',
        main_phone_href: '+576013289068',
        taxez: '',
        currency_number: 6
    },
];

export const PAYMENTS = [
    {
        country: 'Chile',
        payments_method: [
            {
                method: 'TARJETA DE CRÉDITO o DÉBITO',
                state: 'Activación inmediata',
                logos: ['logo-webpay'],
            },
            // , {
            //     method: 'servipag online',
            //     state: 'Activación inmediata',
            //     logos: ['logo-servipag'],
            // }, {
            //     method: 'cupón de pago servipag',
            //     state: 'Activación entre 24 y 48 horas hábiles',
            //     logos: ['logo-servipag'],
            // },
            // {
            //     method: 'Khipu',
            //     state: 'Activación inmediata',
            //     logos: ['logo-khipu'],
            // },
            {
                method: 'Transferencia',
                state: 'Activación inmediata',
                // bank_info: {
                //     bank: 'Banco Itaú S.A.',
                //     number: '№ 0213162404',
                //     company: 'Haulmer SpA.',
                //     rut: '76.795.561-8',
                //     email: 'payment@haulmer.com',
                // },
                logos: ['logo-linkify'],
            }, {
                method: 'Transferencia',
                state: 'Activación inmediata',
                logos: ['logo-itau'],
            }, {
                method: 'PAYPAL',
                state: 'Activación inmediata',
                logos: ['logo-paypal'],
            }
        ]
    }, {
        country: 'Perú',
        payments_method: [
            {
                method: 'tarjeta de crédito o débito',
                state: 'Activación inmediata',
                logos: [
                    'logo-mastercard',
                    'logo-diners',
                    'logo-american-express',
                    'logo-visa'
                ],
            }, {
                method: 'PAGO EN BANCOS',
                state: 'Activación entre 24 y 48 horas hábiles',
                logos: ['logo-bcp'],
            }, {
                method: 'PAGO EFECTIVO',
                state: 'Activación inmediata',
                logos: ['logo-pago-efectivo'],
            }, {
                method: 'Transferencia o depósito bancario',
                state: 'Activación entre 24 y 48 horas hábiles',
                payment_info: {
                    point_1: 'PagoMaster S.A.C.',
                    point_2: '194-2157025-0-30',
                    point_4: '194-2171047-1-76',
                    point_6: 20565999754,
                    logos: ['logo-bcp']
                }
            }, {
                payment_info: {
                    point_1: 'PagoMaster S.A.C.',
                    point_2: '107-300105942-0',
                    point_3: '003-107-003001059420-05',
                    point_4: '107-300105943-8',
                    point_5: '003001059438-07',
                    point_6: 20565999754,
                    logos: ['logo-interbank']
                }
            }, {
                method: 'PAYPAL',
                state: 'Activación inmediata',
                logos: ['logo-paypal'],
            }
        ]
    }, {
        country: 'México',
        payments_method: [
            {
                method: 'tarjeta de crédito',
                state: 'Activación inmediata',
                logos: [
                    'logo-mastercard',
                    'logo-diners',
                    'logo-american-express',
                    'logo-visa'
                ],
            }, {
                method: 'PAGO EN EFECTIVO',
                state: 'Activación entre 24 y 48 horas hábiles',
                logos: [
                    'logo-oxxo',
                    'logo-super-farmacia',
                    'logo-eleven',
                    'logo-ahorro'
                ],
            }, {
                method: 'PAGO EN BANCOS',
                state: 'Activación entre 24 y 48 horas hábiles',
                logos: [
                    'logo-santander',
                    'logo-bankomer',
                    'logo-scotiabank'
                ],
            }, {
                method: 'PAYPAL',
                state: 'Activación inmediata',
                logos: ['logo-paypal'],
            }
        ]
    }, {
        country: 'Argentina',
        payments_method: [
            {
                method: 'PAGO EN EFECTIVO',
                state: 'Activación en 48 horas hábiles',
                logos: [
                    'logo-cobro-express',
                    'logo-pago-facil',
                    'logo-provincia',
                    'logo-rapi-pago',
                    'logo-ripsa'
                ],
            }, {
                method: 'TARJETA DE CRÉDITO O DÉBITO',
                state: 'Activación inmediata',
                logos: [
                    'logo-american-express',
                    'logo-argencard',
                    'logo-cencosud',
                    'logo-cabal',
                    'logo-tarjeta-shopping',
                    'logo-mastercard',
                    'logo-visa'
                ],
            }, {
                method: 'PAYPAL',
                state: 'Activación inmediata',
                logos: ['logo-paypal'],
            }
        ]
    }, {
        country: 'Colombia',
        payments_method: [
            {
                method: 'TARJETA DE CRÉDITO',
                state: 'Activación en 48 horas hábiles',
                logos: [
                    'logo-mastercard',
                    'logo-visa',
                    'logo-diners',
                    'logo-american-express',
                    'logo-codensa'
                ],
            }, {
                method: 'Banco de bogotá',
                state: 'Activación inmediata',
                logos: ['logo-banco-de-bagota'],
            }, {
                method: 'Débito bancario pse',
                state: 'Activación inmediata',
                logos: ['logo-pse'],
            }, {
                method: 'PAGO EN EFECTIVO',
                state: 'Activación en 24 horas hábiles',
                logos: [
                    'logo-baloto',
                    'logo-paga-todo',
                    'logo-apuestas-cucuta',
                    'logo-gana',
                    'logo-gana-gana',
                    'logo-su-chance',
                    'logo-acertemos',
                    'logo-laperla',
                    'logo-au',
                    'logo-jer',
                    'logo-efecty'
                ],
            }, {
                method: 'pago en bancos',
                state: 'Activación inmediata',
                logos: [
                    'logo-banco-de-bagota',
                    'logo-bancolombia',
                    'logo-davivienda'
                ],
            }, {
                method: 'PAYPAL',
                state: 'Activación inmediata',
                logos: ['logo-paypal'],
            }
        ]
    }
];
