import { Component, Input } from '@angular/core';

interface Tariff {
    id: number;
    icon: string;
    title: string;
    price: number;
    sales: number;
    additional: any;
    tariffOptions: Array<TariffOptions>;
}

interface TariffOptions {
    id: number;
    title: string;
    options: Array<Option>;
}

interface Option {
    id: number;
    icon: string;
    title: string;
    actionText?: string;
    tooltip?: string;
    price?: number;
    hourlyFee?: number;
    count: number;
    isChecked: boolean;
    maxCount: number;
}

@Component({
    selector: 'app-section-price',
    templateUrl: './section-price.component.html',
    styleUrls: ['./section-price.component.scss']
})
export class SectionPriceComponent {
    @Input() public showSuptitle = true;

    public cardsArr: Array<Tariff> = [
        {
            id: 0,
            icon: 'icon-sprite-shop',
            title: 'Pyme',
            price: 360000,
            sales: 200,
            additional: {
                licenses: 4,
                price: 80000
            },
            tariffOptions: [
                {
                    id: 1,
                    title: 'Adicionales',
                    options: [
                        {
                            id: 1,
                            icon: 'icon-sprite-users',
                            title: 'Razón social adicional',
                            actionText: '¿Qué es la razón social?',
                            tooltip: 'Nombre con que una entidad registrada legalmente con la que se emitirán tus documentos electrónicos. Podrás agregar una nueva razón social con la cual podrás emitir tus documentos electrónicos',
                            price: 360000,
                            count: 0,
                            isChecked: false,
                            maxCount: 9
                        }
                    ]
                }, {
                    id: 2,
                    title: 'Firma electrónica',
                    options: [
                        {
                            id: 1,
                            icon: 'icon-sprite-feather',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                            actionText: '¿Que es la firma electrónica?',
                            tooltip: 'La Firma Electrónica es lo mismo que el Certificado Digital, son dos formas distintas de llamar a los datos asociados a un documento electrónico que permiten asegurar su integridad e identificar al firmante',
                            count: 1,
                            isChecked: true,
                            maxCount: 20
                        }, {
                            id: 2,
                            icon: 'icon-sprite-feather',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                            count: 0,
                            isChecked: false,
                            maxCount: 20
                        }, {
                            id: 3,
                            icon: 'icon-sprite-feather',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                            count: 0,
                            isChecked: false,
                            maxCount: 20
                        }
                    ]
                }
            ]
        }, {
            id: 1,
            icon: 'icon-sprite-warehouse',
            title: 'Grandes',
            price: 1200000,
            sales: 200,
            additional: {
                licenses: 6,
                price: 100000
            },
            tariffOptions: [
                {
                    id: 1,
                    title: 'Adicionales',
                    options: [
                        {
                            id: 1,
                            icon: 'icon-sprite-users',
                            title: 'Razón social adicional',
                            actionText: '¿Qué es la razón social?',
                            tooltip: 'Nombre con que una entidad registrada legalmente con la que se emitirán tus documentos electrónicos. Podrás agregar una nueva razón social con la cual podrás emitir tus documentos electrónicos',
                            price: 1200000,
                            count: 0,
                            isChecked: false,
                            maxCount: 9
                        }
                        // , {
                        //     id: 2,
                        //     icon: 'icon-sprite-vpn-key',
                        //     title: 'API Key Adicional',
                        //     actionText: '¿Qué es la API Key?',
                        //     tooltip: 'API RESTful. Para que puedas integrar tu propio sistema con nuestro servicio Openfactura.',
                        //     price: 600000,
                        //     count: 0,
                        //     isChecked: false
                        // }, {
                        //     id: 3,
                        //     icon: 'icon-sprite-user',
                        //     title: 'HH Desarrollo',
                        //     actionText: '¿Qué es HH Desarrollo?',
                        //     tooltip: 'Puedes contratar los servicios de nuestros desarrolladores para realizar cualquier trabajo de integración con tu servicio.',
                        //     hourlyFee: 2,
                        //     count: 0,
                        //     isChecked: false
                        // }, {
                        //     id: 4,
                        //     icon: 'icon-sprite-factura',
                        //     title: 'DTE Adicional',
                        //     actionText: '¿Qué es DTE?',
                        //     tooltip: 'Puedes contratar DTE adicionales a los que estan incluidos en tu plan contratado sin problemas.',
                        //     hourlyFee: 5,
                        //     count: 0,
                        //     isChecked: false
                        // }
                    ]
                }, {
                    id: 2,
                    title: 'Firma electrónica',
                    options: [
                        {
                            id: 1,
                            icon: 'icon-sprite-feather',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                            actionText: '¿Que es la firma electrónica?',
                            tooltip: 'La Firma Electrónica es lo mismo que el Certificado Digital, son dos formas distintas de llamar a los datos asociados a un documento electrónico que permiten asegurar su integridad e identificar al firmante',
                            count: 1,
                            isChecked: true,
                            maxCount: 20
                        }, {
                            id: 2,
                            icon: 'icon-sprite-feather',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                            count: 0,
                            isChecked: false,
                            maxCount: 20
                        }, {
                            id: 3,
                            icon: 'icon-sprite-feather',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                            count: 0,
                            isChecked: false,
                            maxCount: 20
                        }
                    ]
                }
            ]
        }
    ];

    public currentTariff = 0;

    public listsOptions = [
        {
            list: [
                {
                    text: '<b>Ilimitada</b> emisión de documentos',
                    icon: 'icon-sprite-unlimited',
                }, {
                    text: '<b>Todos los documentos </b>tributarios disponibles',
                    icon: 'icon-sprite-todos-doc',
                }, {
                    text: 'Migración del SII u otras <b>incluidas</b>',
                    icon: 'icon-sprite-migration-sii',
                }
            ]
        }, {
            list: [
                {
                    text: '<b>Ilimitada </b>cantidad de usuarios',
                    icon: 'icon-sprite-unlimited-users',
                }, {
                    text: 'Habilitación y certificación <b>incluida</b>',
                    icon: 'icon-sprite-included-certification',
                }, {
                    text: '<b>Acceso API </b>ilimitado',
                    icon: 'icon-sprite-acceso-api',
                }, {
                    text: '<b>1 Razón Social</b> incluida',
                    icon: 'icon-sprite-users'
                }
            ]
        }
    ];

    public startPath = 'https://www.openfactura.cl/cart/contact?subproduct=';
    public qtyQueryParams = '&qtyproduct=1';
    public tariffQueryParams = 'OFM';

    public getCurrentTarrif(index): void {
        this.currentTariff = index;
        switch (index) {
            case 0:
                this.tariffQueryParams = 'OFM';
                break;
            case 1:
                this.tariffQueryParams = 'OFG';
                break;
        }
    }

    public getOptionsGueryParams(): string {
        let optionsQueryParams = '';
        this.cardsArr[this.currentTariff].tariffOptions.forEach(card => {
            const checkedCards = card.options.filter(option => option.isChecked === true);

            if (checkedCards) {
                checkedCards.forEach(checkedCard => {
                    if (checkedCard.count > 0) {
                        if (checkedCard.title === 'Razón social adicional') {
                            optionsQueryParams = optionsQueryParams + ',RAZON';
                        }
                        if (checkedCard.title === 'Firma electrónica simple por 1 año') {
                            optionsQueryParams = optionsQueryParams + ',F1';
                        }
                        if (checkedCard.title === 'Firma electrónica simple por 2 años') {
                            optionsQueryParams = optionsQueryParams + ',F2';
                        }
                        if (checkedCard.title === 'Firma electrónica simple por 3 años') {
                            optionsQueryParams = optionsQueryParams + ',F3';
                        }
                    }
                });
            }
        });
        return optionsQueryParams;
    }

    public getCurrentUrl(): string {
        let url = this.startPath + this.tariffQueryParams + this.getOptionsGueryParams() + this.qtyQueryParams;

        this.cardsArr[this.currentTariff].tariffOptions.forEach(card => {
            const checkedCards = card.options.filter(option => option.isChecked === true);
            if (checkedCards) {
                checkedCards.forEach(checkedCard => {
                    if (checkedCard.count > 0) {
                        url = url + ',' + checkedCard.count;
                    }
                });
            }
        });
        return url;
    }

    public getTotalPrice(): number {
        let price = this.cardsArr[this.currentTariff].price;
        this.cardsArr[this.currentTariff].tariffOptions.forEach(card => {
            card.options.forEach(item => {
                if (item.price !== undefined && item.isChecked && item.count > 0) {
                    price = price + (item.price * item.count);
                }
            });
        });
        return price;
    }

}
