export interface UrlList {
    project: string;
    link_sales: string;
    link_support: string;
    link_payment: string;
}

export const ArrUrl: UrlList[] = [
    {
        project: 'bluehosting',
        link_sales: 'https://www.bluehosting.cl/contactar-con-ventas/',
        link_support: 'https://panel.bluehosting.host/submitticket.php?step=2&deptid=3',
        link_payment: 'https://panel.bluehosting.host/submitticket.php?step=2&deptid=3',
    }, {
        project: 'openfactura',
        link_sales: 'https://www.openfactura.cl/contactar-con-ventas/',
        link_support: 'https://my.haulmer.com/submitticket.php?step=2&deptid=1',
        link_payment: 'https://my.haulmer.com/submitticket.php?step=2&deptid=5',
    }, {
        project: 'chilefirmas',
        link_sales: 'https://www.chilefirmas.cl/contactar-con-ventas/',
        link_support: 'https://my.haulmer.com/submitticket.php?step=2&deptid=1',
        link_payment: 'https://my.haulmer.com/submitticket.php?step=2&deptid=5',
    }, {
        project: 'haulmer',
        link_sales: 'https://www.haulmer.com/contactar-con-ventas/',
        link_support: 'https://my.haulmer.com/submitticket.php?step=2&deptid=1',
        link_payment: 'https://my.haulmer.com/submitticket.php?step=2&deptid=5',
    }, {
        project: 'opencloud',
        link_sales: '/contactar-con-ventas/',
        link_support: 'https://panel.opencloud.host/submitticket.php?step=2&deptid=1',
        link_payment: 'https://panel.opencloud.host/submitticket.php?step=2&deptid=2',
    }, {
        project: 'tuu',
        link_sales: 'https://www.tuu.cl/contactar-con-ventas/',
        link_support: 'https://my.haulmer.com/submitticket.php?step=2&deptid=1',
        link_payment: 'https://my.haulmer.com/submitticket.php?step=2&deptid=5',
    },{
        project: 'maquinaazul',
        link_sales: 'http://www.maquinaazul.cl/contactar-con-ventas/',
        link_support: 'https://my.haulmer.com/submitticket.php?step=2&deptid=1',
        link_payment: 'https://my.haulmer.com/submitticket.php?step=2&deptid=5',
    }
];

export class ProjectsUrl {

    constructor() {
    }

    getProjectList(project) {
        const activeProject = ArrUrl.filter(item => item.project === project)[0];
        return activeProject;
    }
}
