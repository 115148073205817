import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CONTACTS } from './../contacts.constants';
import { Component, OnInit, Inject, Input, ViewChild, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';
import { Contact } from '../Contact';
import { Meta } from '../Meta';
import { MatSnackBar } from '@angular/material/snack-bar';
import { retryWhen, delay, take, concat } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Chile } from '../Chile';
import { AppType } from '../assets/website-data';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {
    contact: Contact;
    activeCaptcha = false;
    captcha: any;
    disableButton = false;
    currentCountryCode: string;
    form: FormGroup;
    firstClick = false;
    isSendForm = false;
    siteKey = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';
    hidden: boolean;
    allContactList;
    phoneSelected: string;
    phonehrefSelected: string;
    citySelected: string;
    countrySelected: string;
    flagSelected: string;
    chile;
    region;
    comuna;

    @Input() TLD: string;
    @Input() Meta: Meta;
    @Input() MessageFrom: string;
    @Input() isPromoForm = false;
    @Input() TitleForm = 'Te contactaremos';
    @Input() Title = 'Contactar con Ventas';
    @Input() Title2 = 'Ventas';
    @Input() contactList: Contact[] = CONTACTS;
    @Input() isRent?: boolean;
    @Input() isRequestQuote?: boolean;

    @ViewChild(FormGroupDirective, { static: true }) formDirective: FormGroupDirective;
    // @ViewChild(ReCaptchaComponent) recaptcha: ReCaptchaComponent;

    constructor(@Inject(PLATFORM_ID) private platformId: any,
        private _http: HttpClient,
        private _meta: MetaService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private reCaptchaV3Service: ReCaptchaV3Service,
    ) {
        this.allContactList = CONTACTS;
    }

    ngOnInit(): void {
        this.chile = Chile;
        this.region = this.chile[0];
        this._meta.setTitle(this.Meta.title);
        this._meta.setTag('description', this.Meta.description);
        let keywords = '';
        this.Meta.keywords.forEach(keyword => {
            if (keywords[keywords.length - 1] === keyword) {
                keywords = keywords + keyword;
            } else {
                keywords = keywords + keyword + ', ';
            }
        });

        this._meta.setTag('keywords', keywords);
        this._meta.setTag('author', this.Meta.author);
        this._meta.setTag('og:image:type', 'image/' + this.Meta.image_type);
        this._meta.setTag('og:image', this.Meta.image_path);
        this._meta.setTag('og:image:width', this.Meta.image_width);
        this._meta.setTag('og:image:height', this.Meta.image_height);
        this._meta.setTag('og:image:alt', 'Contactar con ventas');
        this._meta.setTag('og:locale', this.Meta.locale);
        this.contact = this.getContact(this.TLD);
        this.isRent ? this.buildFromPartners() :
                    (this.isRequestQuote ? this.buildFromRequestQuote() : this.buildBasicForm());
        this.changeValidation(this.contact);
        this.phonehrefSelected = this.contact.main_phone_href;
        this.phoneSelected = this.contact.main_phone;
        this.citySelected = this.contact.city;
        this.countrySelected = this.contact.country;
        this.flagSelected = this.contact.flag_url;

        if (isPlatformBrowser(this.platformId)) {
            this.StartCaptcha();
        }
    }

    changeValidation(contact: Contact): void {
        switch(contact.country_code) {
            case 'cl':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{8,9}')]);
                break;
            case 'pe':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{9,10}')]);
                break;
            case 'me':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{10,11}')]);
                break;
            case 'ar':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{11}')]);
                break;
            case 'co':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[0-9]{8,10}')]);
                break;
            case 'us':
                this.form.get('phone').setValidators(
                    [Validators.required, Validators.pattern('[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{6}')]);
                break;
        }
        this.form.get('phone').updateValueAndValidity();
        this.changeCountry(contact);
    }

    changeRegion(region) {
        this.region = region;
    }

    getContact(TLD: string): Contact {
        const aux = (TLD === 'mx' ? 'me' : TLD);
        return this.contactList.filter(c => c.country_code === aux)[0];
    }

    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country_code === this.currentCountryCode);
        return country[0];
    }

    // captchaExpired() {
    //     this.activeCaptcha = false;
    // }

    // handleCorrectCaptcha(ev) {
    //     this.activeCaptcha = true;
    //     this.captcha = ev;
    // }

    btnMsg(): string {
        if (this.disableButton === false) {
            return 'Enviar';
        } else {
            return 'Enviando Solicitud...';
        }
    }

    onSubmit(): void {
        if (this.form.invalid) {
            this.showMessage('¡Ups! Ocurrió un error al enviar sus datos de contacto.', 'REINTENTAR', 0, true);
            return;
        }

        this.disableButton = true;
        const values = this.form.value;
        values['g-recaptcha-response'] = this.captcha;

        const body = new HttpParams()
            .set('title', this.MessageFrom)
            .set('nombreCompleto', values.name)
            .set('telefono', values.phone)
            .set('email', values.email)
            .set('g-recaptcha-response', values['g-recaptcha-response'])
            .set('codigoPais', this.contact.phone_code.substr(1))
            .set('pais', 'Chile' )
            .set('region', this.region.region)
            .set('comuna', this.comuna );
        if (this.MessageFrom === 'OpenFactura') {
            this._http.post('https://api-frontend.haulmer.com/v2/call', body)
                .pipe(
                    retryWhen(err => err.pipe(
                        delay(5000),
                        take(5),
                        concat(throwError('An error occur while trying to post the info'))
                        )
                    )
                )
                .subscribe(() => {
                    this.formDirective.resetForm();
                    this.form.get('phone').setValue(' ');
                    // this.recaptcha.reset();
                    this.activeCaptcha = false;
                    this.disableButton = false;

                    this.showMessage('Solicitud creada! Será contactado en breve por nuestro departamento de ventas', 'CERRAR', 10000);
                }, error => {
                    this.showMessage('¡Ups! Ocurrió un error al enviar sus datos de contacto.', 'REINTENTAR', 0, true);
                });
            this.StartCaptcha();
        } else {
            this._http.post('https://api-frontend.haulmer.com/v2/call', body)
                .pipe(
                    retryWhen(err => err.pipe(
                        delay(3000),
                        take(5),
                        concat(throwError('An error occur while trying to post the info'))
                        )
                    )
                )
                .subscribe(() => {
                    this.formDirective.resetForm();
                    this.form.get('phone').setValue(' ');
                    // this.recaptcha.reset();
                    this.activeCaptcha = false;
                    this.disableButton = false;

                    this.showMessage('Solicitud creada! Será contactado en breve por nuestro departamento de ventas', 'CERRAR', 10000);
                }, error => {
                    this.showMessage('¡Ups! Ocurrió un error al enviar sus datos de contacto.', 'REINTENTAR', 0, true);
                });
            this.StartCaptcha();
        }
    }

    // show spinner of the button
    sendForm(): void {
        this.isSendForm = true;

        setTimeout(() => {
            this.isSendForm = false;
        }, 3000);
    }

    showMessage(bodyText, btnText, duration, reload?): void {
        const bar = this.snackBar.open(bodyText, btnText,
        {
            duration: duration,
            panelClass: 'snackBar-btn'
        });

        if (reload) {
            bar.afterDismissed().subscribe(() => location.reload());
        }
    }

    // selectedCountry(contrySelected) {
    //     const selectedItem = this.allContactList.filter(item => item.country === contrySelected)[0];
    //     this.citySelected = selectedItem.city;
    //     this.countrySelected = selectedItem.country;
    //     this.flagSelected = selectedItem.flag_url;
    //     this.phoneSelected = selectedItem.main_phone;
    //     this.contact = selectedItem;
    // }

    onPhoneBlur(event: any): void {
        if (this.form.get('phone').value == '') this.form.get('phone').setValue(' ');
    }

    onPhoneFocus(event: any): void {
        if (this.form.get('phone').value == ' ') this.form.get('phone').setValue('');
    }

    StartCaptcha(): void {
        this.reCaptchaV3Service.execute(this.siteKey,
            'contactar_ventas',
            (token) => {
                this.activeCaptcha = true;
                this.captcha = token;
                let element = document.getElementsByClassName("grecaptcha-badge")[0];
                element.setAttribute('style', element.getAttribute('style') + 'margin-bottom:80px');
        });
    }

    changeCountry(country): void {
        if (country.country_code !== 'cl') {
             this.hidden = true;
        } else {
            this.hidden = false;
        }
    }

    changeComuna(comuna): void {
        this.comuna = comuna;
    }

    buildFromPartners(): void {
        this.form = this.formBuilder.group({
            name: ['', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]],
            email: ['', [
                Validators.required,
                Validators.email,
                Validators.maxLength(200)
            ]],
            phone: [' ', [
                Validators.required,
                Validators.pattern('[0-9]{8,9}')
            ]],
            country: ['', [
            //     Validators.required
            ]],
            region: ['', [
            //     Validators.required
            ]],
            comuna: ['', [
            //     Validators.required
            ]]
        });
    }

    buildFromRequestQuote(): void {
        this.form = this.formBuilder.group({
            name: ['', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]],
            rut: ['', []],
            phone: [' ', [
                Validators.required,
                Validators.pattern('[0-9]{8,9}')
            ]],
            email: ['', [
                Validators.required,
                Validators.email,
                Validators.maxLength(200)
            ]]
        });
    }

    buildBasicForm(): void {
        this.form = this.formBuilder.group({
            name: ['', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]],
            email: ['', [
                Validators.required,
                Validators.email,
                Validators.maxLength(200)
            ]],
            phone: [' ', [
                Validators.required,
                Validators.pattern('[0-9]{8,9}')
            ]],
        });
    }
}
