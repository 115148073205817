export class Meta {
    title: string;
    description: string;
    keywords: string[];
    author: string;
    image_path: string;
    image_type: string;
    image_width: string;
    image_height: string;
    image_alt: string;
    locale: string;

    constructor(title: string, description: string, author: string, image_path: string,
        image_type: string, image_width: number, image_height: number, image_alt: string) {
        this.title = title;
        this.description = description;
        this.author = author;
        this.image_path = image_path;
        this.image_type = image_type;
        this.image_width = image_width.toString();
        this.image_height = image_height.toString();
        this.image_alt = image_alt;
        this.locale = 'es_CL';
        this.keywords = [];
    }

    public addKeyword(keyword: string): void {
        this.keywords.push(keyword);
    }

    public updateLocale(locale: string): void {
        this.locale = locale;
    }
}
