import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-point-of-sale',
    templateUrl: './section-point-of-sale.component.html',
    styleUrls: ['./section-point-of-sale.component.scss']
})
export class SectionPointOfSaleComponent {
    @Input() public loadedImage;

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
