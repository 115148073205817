import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-card-options',
    templateUrl: './card-options.component.html',
    styleUrls: ['./card-options.component.scss']
})
export class CardOptionsComponent {
    @Input() public data;
    @Input() public currentTariff;

    public activeCard = 0;

    public checkedFirma(card, option) {
        switch (option.id) {
            case 1:
                card[1].isChecked = false;
                card[1].count = 0;
                card[2].isChecked = false;
                card[2].count = 0;
                break;
            case 2:
                card[0].isChecked = false;
                card[0].count = 0;
                card[2].isChecked = false;
                card[2].count = 0;
                break;
            case 3:
                card[0].isChecked = false;
                card[0].count = 0;
                card[1].isChecked = false;
                card[1].count = 0;
                break;
        }
        this.selectOption(option)
    }

    public changeCounter(card, option, type): void {
        switch (type) {
            case 'inc':
                option.count = option.count + 1;
                break;
            case 'dec':
                option.count = option.count - 1;
                break;
        }
        if (option.isChecked==false && option.icon==='icon-sprite-feather') {
            this.checkedFirma(card, option);
        }

        if (option.count === 0) {
            option.isChecked = false;
        } else {
            option.isChecked = true;
        }
    }

    public selectOption(option): void {
        if (option.isChecked) {
            option.count = 0;
        } else {
            option.count = 1;
        }

    }

}
