import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-card-price',
    templateUrl: './card-price.component.html',
    styleUrls: ['./card-price.component.scss']
})
export class CardPriceComponent {
    @Input() public data;
    @Output() public selectedCard = new EventEmitter<number>();

    public activeCard = 0;

    public selectCard(id) {
        this.activeCard = id;
        this.selectedCard.emit(id);
    }

}
