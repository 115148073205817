declare var dataLayer;

export function traceButton(event, type: string): void {
    var target = event.target.id || event.target.parentNode.id;
    dataLayer.push({
        'event': 'traceButton', 'element': {
            'idButton': target,
            'typeButton': type
        }
    },);
}