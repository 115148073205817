import { CONTACTS } from '../../../../../common/contacts.constants';
import { Contact } from '../../../../../common/Contact';
import {
    Component, OnInit, AfterViewInit, HostListener,
    ElementRef, Inject, PLATFORM_ID, ViewChild, Input
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomMenuComponent } from '../dialogs/dialog-custom-menu/dialog-custom-menu.component';

import { URL } from '../../../environments/environment';
import { HeaderDataObject } from './header-data';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit, AfterViewInit {
    @ViewChild('triggerDialog', { static: true }) triggerDialog: ElementRef;
    @Input() public data: HeaderDataObject;

    arrow = 'keyboard_arrow_down';
    isShowMenuSupport: boolean;

    public URL = URL;
    activeMenu = false;
    activeChats = false;
    activeCountry = false;
    activeContacts = false;
    isFadeIn = false;
    isOpenDialog = false;
    isHeaderFixed = false;
    visibilityContactButton = false;

    contactList: Contact[];

    @HostListener('window:scroll')
    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.checkHeaderPosition();
        }

        this.playAnimations();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.arrow = window.innerWidth > 767 ? 'keyboard_arrow_down' : 'keyboard_arrow_right';
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        private _el: ElementRef,
        private _router: Router,
        public dialog: MatDialog) { }

    ngOnInit() {
        this.contactList = CONTACTS.filter(c => c.country_code === 'cl');
        this.arrow = window.innerWidth > 767 ? 'keyboard_arrow_down' : 'keyboard_arrow_right';
    }

    openSignUpView() {
        if (isPlatformBrowser(this.platformId)) {
            window.location.href = URL.OPENFACTURA_SIGNUP_DEMO;
        }
    }

    // open mobile menu
    onClickOpenMenu() {
      const body = this._document.getElementsByTagName('body')[0];
        body.classList.add('open_menu');
    }

    // close mobile menu
    onClickCloseMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.remove('open_menu');
        this.isShowMenuSupport = false;
    }

    // open dropdown menu of the mobile
    openDropdownMenu(menu) {
        if (menu === 'soporte') {
            this.isShowMenuSupport = true;
        }
    }

    // close dropdown menu of the mobile
    closeMenuDropdown(menu) {
        if (menu === 'soporte') {
            this.isShowMenuSupport = false;
        }
    }

    ngAfterViewInit() {
        this.playAnimations();

        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this._document.getElementsByTagName('body')[0].classList.add('loaded');
            }, 1000);
        }
    }

    checkHeaderPosition(): void {
        if (isPlatformBrowser(this.platformId)) {
            const offsetY = window.scrollY;
            const headerEl = this._el.nativeElement.getElementsByTagName('header')[0] as HTMLElement;
            if (offsetY >= 50 || this._document.getElementsByTagName('html')[0].classList.contains('cdk-global-scrollblock')) {
                headerEl.classList.add('fixed');
                this.isHeaderFixed = true;
            } else {
                headerEl.classList.remove('fixed');
                this.isHeaderFixed = false;
            }
        }
    }

    playAnimations(): void {
        this._playVideoOrGif();
        // play animations
        const animations = this._document.getElementsByClassName('animate');
        [].forEach.call(animations, (animation) => {
            if (this.isOnScreenCenter(animation)) {
                animation.classList.add('playing');
            }
        });
        // play parallax
        const parallaxContainer = this._document.getElementsByClassName('block_warranty')[0] as HTMLElement;
        if (!!parallaxContainer) {
            this.animateParallaxContainer(parallaxContainer);
        }
    }

    _playVideoOrGif() {
        if (isPlatformBrowser(this.platformId)) {
            const bodyTag = this._document.getElementsByTagName('body')[0] as HTMLElement;
            if (bodyTag.classList.contains('video')) {
                this._playVideos();
            } else if (bodyTag.classList.contains('gif')) {
                this._showGifs();
            } else {
                // check if we can play the sample video
                const videoSample = this._document.getElementById('videoSample') as HTMLVideoElement;
                if (!videoSample) {
                    return;
                }
                const videoPlayPromise = videoSample.play();
                if (videoPlayPromise !== undefined) {
                    videoPlayPromise
                        .then(() => {
                            // remove video sample
                            bodyTag.classList.add('video');
                            videoSample.remove();
                        }).catch(() => {
                        bodyTag.classList.add('gif');
                    });
                }
            }
        }
    }

    _playVideos(): void {
        this._prepareVideo();
        const videos = this._document.getElementsByTagName('video');
        [].forEach.call(videos, (video) => {
            if (this.isInViewport(video)) {
                video.play();
            } else {
                video.pause();
            }
        });
    }

    _prepareVideo(): void {
        const holderEls = this._document.getElementsByClassName('videoHolder') as HTMLCollectionOf<HTMLElement>;
        [].forEach.call(holderEls, (holderEl) => {
            if (holderEl.classList.contains('media')) {
                return;
            }
            const videoEl = this._document.createElement('video');
            videoEl.classList.add('fadeElement');
            if (holderEl.dataset.poster) {
                videoEl.setAttribute('poster', holderEl.dataset.poster);
            }
            if (holderEl.dataset.id) {
                videoEl.setAttribute('id', holderEl.dataset.id);
            }
            videoEl.setAttribute('playsinline', '');
            holderEl.appendChild(videoEl);
            this._addVideoSource(videoEl, holderEl.dataset.video, 'video/mp4');
            holderEl.classList.add('media');
        });
    }

    _addVideoSource(element: HTMLElement, src: string, type: string): void {
        const source = document.createElement('source');
        source.src = src;
        source.type = type;
        element.appendChild(source);
    }

    _showGifs() {
        const holderEls = this._document.getElementsByClassName('videoHolder') as HTMLCollectionOf<HTMLElement>;
        [].forEach.call(holderEls, (holderEl) => {
            if (holderEl.classList.contains('media')) {
                return;
            }
            const img = new Image();
            img.setAttribute('src', holderEl.dataset.gif);
            holderEl.appendChild(img);
            holderEl.classList.add('media');
        });
    }

    animateParallaxContainer(parallaxContainer) {
        if (isPlatformBrowser(this.platformId)) {
            const parallaxEl = parallaxContainer.getElementsByClassName('block_warranty__txt')[0] as HTMLElement;
            const parallaxContainerRect = parallaxContainer.getBoundingClientRect();
            const ratio = parallaxContainerRect.height / window.innerHeight;
            const step = (parallaxContainerRect.bottom - window.innerHeight + 200) * ratio + 'px';
            parallaxEl.style.transform = 'translateY(' + step + ')';
        }
    }

    isInViewport(elem) {
        if (isPlatformBrowser(this.platformId)) {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 104 && bounding.bottom <= (window.innerHeight || this._document.documentElement.clientHeight)
            );
        }
    }

    isOnScreenCenter(elem) {
        if (isPlatformBrowser(this.platformId)) {
            const bounding = elem.getBoundingClientRect();
            const windowHeight = window.innerHeight || this._document.documentElement.clientHeight;
            let fromBottom, fromTop, elCenter;
            fromBottom = windowHeight - windowHeight * 0.4;
            fromTop = windowHeight * 0.4;
            elCenter = bounding.top + bounding.height / 2;
            return (
                elCenter >= fromTop && elCenter <= fromBottom
            );
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    menuToggle() {
        if (isPlatformBrowser(this.platformId)) {
                if (this.activeMenu) {
                    this.menuClose();
                } else {
                    this.isFadeIn = true;
                    setTimeout(() => {
                        this.activeMenu = true;
                    }, 100);
                }
        }
    }

    openDialogChats() {
        if (isPlatformBrowser(this.platformId)) {
            this.activeMenu = false;
            const url = 'http://chat3.onlinesoporte.net/link/tow_index.php?bmVnb3RpdW0K=T3BlbmZhY3R1cmFDaGlsZQ==&b3ZnZXRpdm8K=VmVudGEK';
            window.open(url, '', 'width=590,height=760,left=0,top=0,resizable=yes,menubar=no,location=no,status=yes,scrollbars=yes');
            return false;
        }
    }

    openDialogCustomMenu() {
        if (window.innerWidth > 767) {
            this.dialog.open(DialogCustomMenuComponent, {
                panelClass: 'dialogCustomMenu',
                width: '238px',
                backdropClass: 'transparent',
                position: {
                    top: '90px',
                    left: this.triggerDialog.nativeElement.getBoundingClientRect().left - 54 + 'px'
                }
            });
        }
    }

    menuClose() {
        this.isFadeIn = false;
        this.activeMenu = false;
        this.isOpenDialog = false;
    }

    clickCloseMenu() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth > 1200) {
                this.isFadeIn = false;
                this.activeMenu = false;
            }
        }
    }

    mouseHideMenu() {
        if (this.isOpenDialog) {
            return;
        }
        this.menuClose();
    }

    menuFadeIn() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth > 1200) {
                this.activeMenu = true;
            }
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    getClassHeader() {
        this.visibilityContactButton = this._router.url === '/contactar-con-ventas/.';

        if (this._router.url === '/contactar-con-ventas/.' || this._router.url === '/contactar-con-soporte/.') {
            return 'non-sticky';
        }
    }
}
