import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

declare var MessageBirdChatWidget: any;

@Component({
    selector: 'app-pop-up-contacts',
    templateUrl: './pop-up-contacts.component.html',
    styleUrls: ['./pop-up-contacts.component.sass'],
})
export class PopUpContactsComponent {
    constructor(
        public dialogRef: MatDialogRef<PopUpContactsComponent>
    ) {}

    public close(): void {
        this.dialogRef.close();
    }

    public openChat(): void{
        MessageBirdChatWidget.toggleChat(true);
        setTimeout(() => {
            this.close();
        }, 100);
    }
}
