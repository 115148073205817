import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { HEADER_DATA, HeaderDataObject } from './shared/header/header-data';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    title = 'app';

    constructor(
        private _router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.getHeaderData();
        }
    }

    getClass() {
        if (
            this._router.url === '/' ||
            this._router.url === '/formas-de-pago/.' ||
            this._router.url.indexOf('/integraciones') !== -1
        ) {
            return 'bg_blue bg_transp';
        } else if (this._router.url === '/factura-electronica/caracteristicas/.') {
            return 'bg_blue_light';
        } else if (this._router.url === '/factura-electronica/precios/.' ||
            this._router.url === '/factura-electronica/api/.') {
            return 'bg_transp';
        } else if (this._router.url === '/contactar-con-ventas/.' ||
            this._router.url === '/solitar-cotizacion/.' ||
            this._router.url === '/contactar-con-soporte/.') {
            return 'header-contact';
        }
        return;
    }

    getHeaderData(): HeaderDataObject {
        if (this._router.url.indexOf('/integraciones') !== -1) {
            return HEADER_DATA.integrations;
        } else {
            return HEADER_DATA.default;
        }
    }
}
