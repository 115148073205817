import { Component, Input, OnInit } from '@angular/core';

interface Item {
    subTitle: string;
    titleHtml: string;
    imageUrl: string;
    iconName: string;
}

export interface ProfsData {
    subTitle: string;
    titleHtml: string;
    automatization: Item;
    issuance: Item;
    share: Item;
    sii: Item;
}

@Component({
    selector: 'app-integrations-profs',
    templateUrl: './integrations-profs.component.html',
    styleUrls: ['./integrations-profs.component.sass']
})
export class IntegrationsProfsComponent implements OnInit {
    @Input() blockData: ProfsData;
    public loadFirstTwo = false;
    public loadLastTwo = false;

    public data: ProfsData;

    public animationCardsData = [
        { heading: 'Artículo 1.241', info: 'Factura de venta SKU - 1284' },
        { heading: 'Artículo 1.222', info: 'Factura de venta SKU - 1384' },
        { heading: 'Artículo 1.243', info: 'Factura de venta SKU - 1344' },
        { heading: 'Artículo 1.555', info: 'Factura de venta SKU - 1254' },
        { heading: 'Artículo 1.134', info: 'Factura de venta SKU - 1271' }
    ];

    constructor() {
    }

    ngOnInit() {
        this.data = this.blockData;
    }

}
