import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { MetaService } from '@ngx-meta/core';
import { Router } from '@angular/router';

import { URL } from '../../environments/environment';
import { ScrollToClass } from '../../../../common/scrollTo.class';;
import { MatDialog } from '@angular/material/dialog';
import { PopUpContactsComponent } from '../shared/pop-up-contacts/pop-up-contacts.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent extends ScrollToClass implements OnInit, AfterViewInit {
    public URL = URL;
    public activeTabIndex = 0;
    oldTabIndex = this.activeTabIndex;
    isNotSelected = true;
    activeConsultantId: number;

    // lazy loading images
    showImage1 = false;
    showImage2 = false;
    showImage3 = false;
    showImage4 = false;
    showImage5 = false;

    // data tabPlan
    dataPlans;

    consultants = [
        {
            id: 1,
            url_image: '/assets/images/violeta-ibarra.png',
            title: 'Asesora Tributario',
            name: 'Violeta Ibarra',
            url_calendar: 'https://calendly.com/openfactura-asesoria-contable/violeta-ibarra-vasquez/?back=1'
        }, {
            id: 2,
            url_image: '/assets/images/sebastian-pitto.png',
            title: 'Asesor Tributario',
            name: 'Sebastián Pitto',
            url_calendar: 'https://calendly.com/openfactura-asesoria-contable/sebastian-pitto-vidal/?back=1'
        }, {
            id: 3,
            url_image: '/assets/images/kena-figeredo.png',
            title: 'Asesora Tributario',
            name: 'Kena Figeredo',
            url_calendar: 'https://calendly.com/openfactura-asesoria-contable/kena-figeredo-perdomo/?back=1'
        }, {
            id: 4,
            url_image: '/assets/images/daniela-santibanez.png',
            title: 'Asesora Tributario',
            name: 'Daniela Santibañez',
            url_calendar: 'https://calendly.com/openfactura-asesoria-contable/daniela-santibanez-ugaz/?back=1'
        }, {
            id: 5,
            url_image: '/assets/images/maria-herrera.png',
            title: 'Asesora Tributario',
            name: 'María Herrera',
            url_calendar: 'https://calendly.com/openfactura-asesoria-contable/maria-herrera/?back=1'
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
        private readonly _meta: MetaService,
        private _el: ElementRef,
        private dialog: MatDialog
    ) {
        super(_el);
    }

    ngOnInit() {
        this._meta.setTitle('FACTURA ELECTRÓNICA | Facturación Electrónica N°1 de Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenFactura es la Factura Electrónica #1 de Chile con el Sistemas de Facturación Electrónica Más Moderno y 100% Cloud del Mercado.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'factura electrónica, sistema de factura electrónica, plataforma de factura electrónica, factura electrónica ilimitada, facturación electrónica, sistema de facturación electrónica, plataforma facturación electrónica, facturación electrónica chile, facturación electrónica ilimitada, boleta electrónica, boleta electrónica ilimitada, guía de despacho electrónica, dte, dte ilimitados');
        this._meta.setTag('author', 'OpenFactura');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENFACTURA_WEBSITE + '/assets/images/homepage/openfactura-interface.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Sistema Factura Electrónica Ilimitada #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth >= 1200) {
                this.initSlider();
            }

            setTimeout(() => {
                this.openDialog();
            }, 10000);

        }
        this.addConsultantSelection();
    }

    private openDialog(): void{
        const dialogRef = this.dialog.open(PopUpContactsComponent, {
            width: '656px'
          });

          dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
          });
    }

    addConsultantSelection() {
        const consultantsContainer = this._document.getElementById('slider-wrapper') as HTMLElement;
        consultantsContainer.addEventListener('click', ev => {
            const consultantEl = this._findAncestorFromEl(ev.target, 'wrap_slider__item');

            if (!!consultantEl) {
                const consultantsList = consultantsContainer.getElementsByTagName('li') as unknown as NodeListOf<HTMLElement>;
                const isConsultantWasActive = consultantEl.classList.contains('itemActive');
                if (isConsultantWasActive) {
                    consultantEl.classList.remove('itemActive');
                    this.activeConsultantId = undefined;
                } else {
                    [].forEach.call(consultantsList, (el) => {
                        el.classList.remove('itemActive');
                    });
                    consultantEl.classList.add('itemActive');
                    this.activeConsultantId = consultantEl.getElementsByClassName('name')[0].getAttribute('id');
                }
                this.isNotSelected = isConsultantWasActive;
            }

        });

    }

    initSlider() {
        tns({
            container: '#slider-wrapper ul',
            slideBy: 1,
            items: 5,
            speed: 400,
            gutter: '5px',
            nav: false
        });
    }

    tabChanged() {
        const videoContainer = this._document.getElementsByClassName('slider_tab__video')[0] as HTMLElement;
        const videoItems = videoContainer.getElementsByClassName('wrap_video');
        const targetItem = videoItems[this.activeTabIndex];
        [].forEach.call(videoItems, (item) => {
            item.classList.remove('active_video', 'toRight', 'toLeft');
        });

        targetItem.classList.add('active_video');

        this.oldTabIndex = this.activeTabIndex;
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) ;
        return el;
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    goToUrl(url): void {
        window.open(url, '_blank');
    }

    openCalendar() {
        if (isPlatformBrowser(this.platformId)) {
            this.consultants.forEach(consultant => {
                if (Number(consultant.id) === Number(this.activeConsultantId)) {
                    window.open(consultant.url_calendar);
                }
            });
        }
    }

    navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }
}
