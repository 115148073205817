import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MetaService } from '@ngx-meta/core';

import { ProfsData } from './templates/integrations-profs/integrations-profs.component';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html'
})
export class IntegrationsComponent implements OnInit, OnDestroy {
    public URL = URL;

    public showIntegrations = false;
    public shouldLoadAnimation = false;

    public profsData: ProfsData = {
        subTitle: 'BENEFICIOS Generales',
        titleHtml: '<h2 class="title2">Explora las cualidades de <br>' +
                    '<span class="underline-bg"><b>nuestros plugins</b></span></h2>',
        automatization: {
            subTitle: 'Automatiza la emisión de documentos',
            titleHtml: '<b>Las boletas electrónicas se generarán</b> de manera automática al finalizar la venta',
            imageUrl: '/assets/images/integrations/home/profs/automatization',
            iconName: 'hand'
        },
        issuance: {
            subTitle: 'Página de emisión de documentos electrónicos',
            titleHtml: '<b>Tus clientes podrán emitir por sí mismos, </b> ' +
                        'boletas y facturas en la página que le enviaremos',
            imageUrl: '/assets/images/integrations/home/profs/issuance',
            iconName: 'documents'
        },
        share: {
            subTitle: 'Comparte, descarga e imprime',
            titleHtml: '<b>Tu cliente podrá imprimir descargar, </b>' +
                        'y/o enviar una copia de su boleta o factura electrónica',
            imageUrl: '/assets/images/integrations/home/profs/share',
            iconName: 'share'
        },
        sii: {
            subTitle: 'Compatible 100% con el SII',
            titleHtml: '<b>Nuestro sistema está certificado por el SII</b> lo que nos permite estar sincronizados.',
            imageUrl: '/assets/images/integrations/home/profs/sii',
            iconName: 'sii'
        }
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private readonly _meta: MetaService
    ) {
    }

    ngOnInit() {
        this.document.body.classList.add('js-integrations');

        this._meta.setTitle('Integración Factura Electrónica para Ecommerce | Openfactura');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Automatiza la emisión de boletas electrónicas o permite que sean tus clientes quienes emitan sus boletas y facturas con la página de emisión de OpenFactura');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'Factura electrónica para ecommerce, facturación electrónica para ecommerce, factura electronica integracion, facturacion electronica, factura electronica');
        this._meta.setTag('author', 'OpenFactura');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENFACTURA_WEBSITE + '/assets/images/integrations/home/integrations_home.png');
        this._meta.setTag('og:image:width', '1200');
        this._meta.setTag('og:image:height', '1200');
        this._meta.setTag('og:image:alt', 'Integración Factura Electrónica para Ecommerce | Openfactura');
        this._meta.setTag('og:localte', 'es_CL');

        setTimeout(() => {
          this.shouldLoadAnimation = true;
        }, 1000);
    }

    ngOnDestroy(): void {
        this.document.body.classList.remove('js-integrations');
    }

    public goTo(url: string): void {
        window.scroll(0, 0);
        this.router.navigateByUrl(`/factura-electronica/integraciones/${url}/.`);
    }

}
